<script>
	import { onMount } from "svelte";

	onMount(() => {
		window.location.href = '/pds';
	});
</script>

<svelte:head>
	<title>PD</title>
	<meta content="PD" name="description" />
</svelte:head>
